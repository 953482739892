import React from 'react'

import { 
  ContactFormSmall, 
  ContactFormInputElement, 
} from './components'


export default class ContactFormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value || '', };
  }

  // If the previous value state was empty and the new one is not, update state. 
  componentDidUpdate(prevProps) {
    if( prevProps.value != this.props.value && prevProps.value === '' && !!this.props.value ){
      this.setState({ value: this.props.value });
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      if( this.props.onChange ){
        this.props.onChange( this.state.value );
      }
    })
  }

  render() {
    return (
      <div>
        <ContactFormSmall>{this.props.label}</ContactFormSmall>
        <ContactFormInputElement 
          onChange={this.onChange}
          placeholder={this.props.placeholder} 
          required={this.props.required} 
          type={this.props.type} 
          name={this.props.name}
          value={this.state.value}
        />
      </div>
    );
  }
};