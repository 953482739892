import styled from 'styled-components'

export const FloatingButton = styled.div`
  color: #fff;
  border-radius: 30px;
  padding: 14px 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #F04E29;
  border-color: #F04E29;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover{
    background-color: #e33710;
    border-color: #d7340f;
  }
`

export const CarouselContainer = styled.div`
  max-width: 700px;
  width: 700px;
  min-height: 540px;
  z-index: 1050;
  background: #ffffff;
  border-radius: 15px;
  padding: 25px 40px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 24px;

  @media (max-width : 768px) {
    width: 90%;
    font-size: 22px;
    padding: 20px 25px;
  }

  @media (max-width : 420px) {
    font-size: 20px;
    padding: 16px;
    border-radius: 18px;
  }
`

export const CarouselPanel = styled.div`
  position: relative;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
`

export const CarouselHeader = styled.div`
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 1.667em;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 22px;
  color: #000000;
`
export const CarouselSubHeader = styled.div`
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 0.833em;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 22px;
  color: #000000;
`

export const CarouselP = styled.p`
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 0.75em;
  letter-spacing: -0.1px;
  color: #363636;
`

export const ContactFormSmall = styled.small`
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 0.583em;
  line-height: 17px;
  color: #363636
  margin: 0;
  font-weight: bold;
`

export const ContactFormInputElement = styled.input`
  font-family: 'Raleway', Helvetica, sans-serif;
  border: 0;
  margin: 0;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
  display: block;
  font-size: 0.9em;
  border-radius: 0;
  background: transparent;
  margin-bottom: 1.66em;

  @media (max-width : 420px) {
    font-size: 0.75em;
  }

  &::placeholder{
    color: #8D94A1;
  }
`

export const ContactFormTextarea = styled.textarea`
  border: 0;
  margin: 0;
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
  min-height: 100px;
  display: block;
  font-size: 1em;
  border-radius: 0;
  background: transparent;
  margin-bottom: 1.5rem;
  color: #000000;

  &::placeholder{
    color: #8d94a1;
  }
`

export const ContactFormSelectContainer = styled.div`
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  padding: 0px 8px;
`

export const ContactFormSelectElement = styled.select`
  margin: 0;
  border: 0;
  width: 100%;
  display: block;
  font-size: 0.9em;
  background: transparent;
  padding: 8px 0px;

  @media (max-width : 420px) {
    font-size: 0.75em;
  }
`

export const ContactFormButton = styled.button`
  font-family: "Lucida Grande", Helvetical, sans-serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  background-color: #F04E29;
  border-color: #F04E29;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.667em;
  line-height: 18px;
  border-radius: 27px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 15px 50px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;

  &:disabled{
    color: #fff;
    background-color: #A3A0A0;
    border-color: #A3A0A0;
    cursor: not-allowed;
  }
`
