import React from 'react'
import ReactDOM from 'react-dom'

import ContactForm from '../components/contact_form'


document.addEventListener((typeof Turbolinks === 'undefined' ? 'DOMContentLoaded' : 'turbolinks:load'), () => {
  if( !window.BuildBook ) window.BuildBook = { config: {} };

  ReactDOM.render(
    <ContactForm 
      ref={(el) => { window.BuildBook.contactForm = el }} 
      teamId={window.BuildBook.config.teamId} 
    />,
    document.body.appendChild(document.createElement("div"))
  )

  const classNames = ["open-buildbook-contact-form", window.BuildBook.targetClassName];
  classNames.forEach( className => {
    if( !!className ){
      const elements = document.getElementsByClassName(className);
      for (var i=0; i < elements.length; i++) {
        elements[i].onclick = function(){
          window.BuildBook.contactForm.openModal();
        }
      };
    }
  });
})
