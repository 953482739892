import React from 'react'

import { 
  ContactFormSmall, 
  ContactFormSelectElement,
  ContactFormSelectContainer,
} from './components'

export default class ContactFormSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', };
  }

  onChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      if( this.props.onChange ){
        this.props.onChange( this.state.value );
      }
    })
  }

  render() {
    return (
      <div>
        <ContactFormSmall>{this.props.label}</ContactFormSmall>
        <ContactFormSelectContainer>
          <ContactFormSelectElement
            onChange={this.onChange}
            placeholder={this.props.placeholder} 
            name={this.props.name} 
            defaultValue={""}
          >
            {!!this.props.placeholder && (
              <option disabled="disabled" value="">{this.props.placeholder}</option>
            )}
            {this.props.values.map((v, i) => <option key={i}>{v}</option>)}
          </ContactFormSelectElement>
        </ContactFormSelectContainer>
      </div>
    );
  }
};