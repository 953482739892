import React from 'react'
import axios from 'axios'
import * as SmartyStreetsSDK from "smartystreets-javascript-sdk"
import Autocomplete from "react-autocomplete";

import CreateUUID from '../../shared/create_uuid'
import Modal from '../modal'

import { 
  CarouselContainer, 
  CarouselPanel, 
  CarouselHeader, 
  CarouselSubHeader, 
  CarouselP, 
  ContactFormSmall, 
  ContactFormButton, 
  ContactFormTextarea,
  FloatingButton,
} from './components'

import ContactFormInput from './contact_form_input'
import ContactFormSelect from './contact_form_select'


export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const key = '35899647884659686';
    const credentials = new SmartyStreetsCore.SharedCredentials(key);

    this.ss_client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);
    
    this.state = {
      user: { uuid: CreateUUID(), dirty: false, },
      address: { },
      autocomplete_values: [],
      submitting: false,
      currentPanel: 0,
      showSecondary: false,
    };
  }

  resetForm = () => {
    this.setState((state, props) => {
      state.user = { uuid: CreateUUID(), dirty: false, };
      state.address = {};
      state.submitting = false;
      state.currentPanel = 0;
      return state;
    });
  }

  submitData = () => {
    const { user, address } = this.state;
    const baseUrl = process.env.DEFAULT_URL_HOST || 'https://buildbook.co'
    
    if( this.validate() && user.dirty && !this.state.submitting ){
      this.setState({ submitting: true }, () => {
        axios.post(`${baseUrl}/pro/${this.props.teamId}.json`, { user, address }).then(res => {
          this.setState((state, props) => {
            state.submitting = false;
            if( res.status === 200 ) state.user.dirty = false;
            return state;
          });
        });
      });
    }
  }

  validate = () => {
    if( this.form ) return this.form.reportValidity();
  }

  openModal = () => {
    if( this.modal ) this.modal.openModal();
  }

  closeModal = () => {
    if( this.modal ) {
      this.modal.closeModal();
      this.resetForm();
    }
  }

  changeUser = (prop, val) => {
    this.setState((state, props) => {
      state.user[prop] = val;
      state.user.dirty = true;
      return state;
    });
  }

  changeAddress = (prop, val) => {
    this.setState((state, props) => {
      state.address[prop] = val;
      return state;
    });
  }

  forward = () => {
    if( this.validate() ){
      if( this.state.currentPanel === 2 ){ // 2 transitioning to 3
        this.setState((state, props) => {
          state.user.completed = true;
          state.user.dirty = true;
          return state;
        }, () => {
          this.submitData();
          this.setState({ currentPanel: this.state.currentPanel + 1 });
        });
      } else {
        this.setState({ currentPanel: this.state.currentPanel + 1 });
      }
    }
  }

  backward = () => {
    this.setState({ currentPanel: this.state.currentPanel - 1 });
  }

  finish = () => {
    this.closeModal()
    this.resetForm();
  }

  autocompleteRequest = () => {
    if( !this.state.user.location || this.state.user.location.length === 0 ){
      this.setState({ autocomplete_values: [] });
    }else{
      const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
      const search = new Lookup( this.state.user.location );
      this.ss_client.send(search).then((data) => {
        const values = data.result.map(function(row){ 
          var label = row.streetLine + ", " + row.city + ", " + row.state + " " + row.zipcode;
          if( row.secondary.length > 0 ){
            label = row.streetLine + " (" + row.secondary + ")" + ", " + row.city + ", " + row.state + " " + row.zipcode
          }
          return {
            label,
            value: row.streetLine + ", " + row.city + ", " + row.state + " " + row.zipcode,
            data: row,
          }; 
        });

        this.setState({ autocomplete_values: values.slice(0, 5)});
      }).catch(function(e){
        this.setState({ autocomplete_values: [] });
      });
    }
  }

  _renderButton = () => {
    const config = window.BuildBook.config;
    if( !config.showButton ) return;

    let styles = {};

    if( config.buttonPosition === 'top' ){
      styles.top = "20px";
      styles.bottom = 'unset';
    }

    return (
      <FloatingButton 
        ref={ ref => this.button = ref } 
        onClick={this.openModal}
        style={styles}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width: '16px', height: '16px', marginRight: '4px', marginTop: '-4px'}}>
          <path fill="#fff" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
        </svg>
        <span>Contact Us</span>
      </FloatingButton>
    ); 
  }

  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <Modal ref={ ref => this.modal = ref } hideCloseButton={true}> 
          <CarouselContainer>
            <button style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 10000, border: 0, background: 'transparent'}} onClick={this.closeModal}>
              <svg width="18px" height="18px" viewBox="0 0 18 18">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g transform="translate(-703.000000, -79.000000)" stroke="#8D94A1" strokeWidth="2">
                      <g transform="translate(704.000000, 80.000000)">
                        <line x1="16" y1="0" x2="0" y2="16" id="Path"></line>
                        <line x1="0" y1="0" x2="16" y2="16" id="Path"></line>
                      </g>
                    </g>
                  </g>
              </svg>
            </button>
            <form ref={ref => this.form = ref } onSubmit={e => e.preventDefault()} autoComplete="off">
              <CarouselPanel style={{display: this.state.currentPanel === 0 ? 'inline-block' : 'none'}}>
                <CarouselHeader>Contact Us</CarouselHeader>
                <CarouselSubHeader>Tell us about yourself!</CarouselSubHeader>
                <ContactFormInput onChange={v => this.changeUser('name', v)} value={user.name} name="name" placeholder="Jane Doe" type="text" label="Your Name (Required)" required="required" />
                <ContactFormInput onChange={v => this.changeUser('email', v)} value={user.email} name="email" placeholder="example@youremail.com" type="email" label="Your Email (Required)" required="required" />
                <ContactFormInput onChange={v => this.changeUser('phone', v)} value={user.phone} name="phone" placeholder="555-555-5555" type="phone" label="Your Phone" required="" />

                <ContactFormButton disabled={!this.state.user.name || !this.state.user.email } onClick={this.forward}>
                  Next →
                </ContactFormButton>
              </CarouselPanel>

              <CarouselPanel style={{display: this.state.currentPanel === 1 ? 'inline-block' : 'none'}}>
                <CarouselHeader>Contact Us</CarouselHeader>
                <CarouselSubHeader>Tell us about your project!</CarouselSubHeader>
                <ContactFormSelect onChange={v => this.changeUser('job_type', v)} name="job_type" placeholder="Please Select"  label="What type of project is it?" values={['New Construction', 'Gut Renovation', 'Kitchen Remodel', 'Bathroom Remodel', 'Addition', 'Other']} />

                <ContactFormSmall>What's the property address?</ContactFormSmall>
                <Autocomplete
                  inputProps={{ id: 'address-autocomplete' }}
                  wrapperStyle={{ 
                    width: '100%',
                  }}
                  value={this.state.user.location}
                  items={this.state.autocomplete_values}
                  getItemValue={(item) => item.label}
                  onSelect={(value, item) => {
                    this.setState((state, props) => {
                      state.user.location = value;
                      state.autocomplete_values = [];
                      state.address.address1 = item.data.streetLine;
                      state.address.city = item.data.city;
                      state.address.state = item.data.state;
                      state.address.zip = item.data.zipcode;
                      if(!!item.data.secondary) state.address.address2 = item.data.secondary + " ";
                      return state;
                    }, () => { if(!!this.state.address.address2) this.secondary.focus(); });
                  }}
                  onChange={(event, value) => {
                    this.setState((state, props) => {
                      state.user.location = value;
                      return state;
                    }, () => { 
                      if( !!this.state.user.location ) {
                        this.autocompleteRequest();
                      }else{
                        this.setState({ autocomplete_values: [] });
                      }
                    })
                  }}
                  renderMenu={children => {
                    return (
                      <div style={{
                        position: 'absolute',
                        zIndex: 100000000,
                        width: '100%',
                        marginTop: '-1.5rem',
                        border: '1px solid #D8D8D8',
                        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
                        borderRadius: '5px',
                        display: this.state.autocomplete_values.length > 0 ? 'block' : 'none',
                      }}>
                        {children}
                      </div>
                    )
                  }}
                  renderInput={(props) => {
                    return (
                      <input 
                        style={{                  
                          border: 0, 
                          margin: 0,
                          borderBottom: '1px solid #D8D8D8',
                          width: '100%',
                          display: 'block',
                          fontSize: '1em',
                          borderRadius: 0,
                          background: 'transparent',
                          marginBottom: !!this.state.address.address2 ? '0.853em' : '1.667em',
                        }} 
                        autoComplete="off"
                        placeholder="Enter a street address"
                        {...props} 
                      />
                    )
                  }}
                  renderItem={(item, isHighlighted) => {
                    return (
                      <div
                        style={{
                          background: isHighlighted ? '#F04E29' : '#ffffff',
                          color: isHighlighted ? '#ffffff' : '#8D94A1',
                          cursor: 'pointer',
                          padding: '4px',
                        }}
                        key={item.label}
                      >{item.label}</div>
                    );
                  }}
                />

                <input
                  value={!!this.state.address['address2'] ? this.state.address.address2 : ''} 
                  ref={ref => this.secondary = ref}
                  onChange={e => this.changeAddress('address2', e.target.value)} 
                  style={{                  
                    border: 0, 
                    margin: 0,
                    borderBottom: '1px solid #D8D8D8',
                    width: '100%',
                    display: !!this.state.address.address2 ? 'block' : 'none',
                    fontSize: '1em',
                    borderRadius: 0,
                    background: 'transparent',
                    marginBottom: '1.667em',
                  }} 
                />

                <ContactFormButton disabled={!this.state.user.name || !this.state.user.email } onClick={this.forward}>
                  Next →
                </ContactFormButton>
              </CarouselPanel>

              <CarouselPanel style={{display: this.state.currentPanel === 2 ? 'inline-block' : 'none'}}>
                <CarouselHeader>Contact Us</CarouselHeader>
                <CarouselSubHeader>Tell us about your needs!</CarouselSubHeader>
                <ContactFormSelect onChange={v => this.changeUser('budget', v)} name="budget" placeholder="Please Select"  label="What's your rough budget?" values={['$0-$10k', '$10k-$25k', '$25k-50k', '$50-$100k', '$100k-$250k', '$250k-$500k', '$500k-$1m', '$1m-$2m', '$2 million or more']} />
                <ContactFormSelect onChange={v => this.changeUser('project_start', v)} name="project_start" placeholder="Please Select"  label="What's your timeline?" values={['Immediate', '1-3 Months', '3-6 Months', '6-12 Months', '1 Year or More']} />
                <ContactFormSmall>Anything else you want to share?</ContactFormSmall>
                <ContactFormTextarea name="notes" onChange={e=> this.changeUser('notes', e.target.value)} placeholder="Project details, Pinterest links, etc.."></ContactFormTextarea>

                <ContactFormButton disabled={!this.state.user.name || !this.state.user.email } onClick={this.forward}>
                  Send →
                </ContactFormButton>
              </CarouselPanel>

              <CarouselPanel style={{display: this.state.currentPanel === 3 ? 'inline-block' : 'none'}}>
                <CarouselHeader>Thank you!</CarouselHeader>

                <div style={{width: '100%', margin: '80px 0 40px 0', display: 'flex', justifyContent: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width: '68px', height: '68px',}}>
                    <script xmlns="" id="tinyhippos-injected"/>
                    <path fill="#F04E29" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/>
                  </svg>
                </div>

                <CarouselP style={{ fontWeight: 'bold', marginBottom: 0, textAlign: 'center' }}>Got it! We’ve received your info and will be in touch soon.</CarouselP> 
                <CarouselP style={{ textAlign: 'center' }}>We appreciate your interest in working with us, and look forward to talking about your project!</CarouselP>
                <ContactFormButton onClick={this.finish}>
                  Close
                </ContactFormButton>
              </CarouselPanel>
            </form>
          </CarouselContainer>
        </Modal>
        {this._renderButton()}
      </React.Fragment>
    );
  }
}